const {navigate} = require("gatsby");
const {wrapPageElement, wrapRootElement} = require("./src/components/layout/layout")
exports.wrapPageElement = wrapPageElement
exports.wrapRootElement = wrapRootElement
exports.onPreRouteUpdate = ({ location, prevLocation }) => {
    if(!prevLocation){
        const {pathname} = location;
        const lang = /^\/en\//.test(pathname) ? 'en' :'fr';
        const project_rewrite = lang==='en' ? 'project':'projet';
        const is_on_project_regexp = new RegExp(`${lang}/(${project_rewrite}/${project_rewrite}\\d+|introduction)`);
        const is_on_project = is_on_project_regexp.test(pathname);
        const introduction_path = `/${lang}/introduction`;
        if(!is_on_project && !window.introduction_ready){
            navigate(introduction_path);
            setTimeout(function(){
                window.introduction_ready = true;
                navigate(pathname);
            },4000);
        }
    }
}
