import React, {useEffect, useState} from "react"
import {Link} from "gatsby"
import "reset.css"
import "../../scss/app.scss"
import Nl2br from "../utils/nl2br";

import Languages from "../utils/languages"
import Links from "./links";
const lang_default = Languages[0]

const Header = ({pageContext,location,paused=false})=>{
    const [navVisible,setNavVisible] = useState(false);
    function toggleNav(){
        setNavVisible(!navVisible)
    }

    const locationState = location.state?.key||'default'
    useEffect(()=>{
        setNavVisible(false)
    },[locationState])

    const {lang='fr', slug} = pageContext


    const links = Links()
    const urlLang = (slug,l=lang)=>links[slug][l].uri
    const titleLang = (slug)=>links[slug][lang].title
    const siteMeta = (key,l=lang)=>links[l][key]||links[lang_default][key]
    const homeUrl = urlLang('projects')
    const siteTitle = siteMeta('title')
    const email = siteMeta('email')
    const phone = siteMeta('phone')
    const phoneFormat = phone.replace(/(\+\d\d)(\d)(\d\d)(\d\d)(\d\d)(\d\d)/,'$1 (0) $2 $3 $4 $5 $6')
    const facebook = siteMeta('facebook')
    const instagram = siteMeta('instagram')

    return (
        <header className={`site-header ${paused && 'site-header--paused'}`}>
            <Link to={homeUrl} className={"site-ornament"} aria-label={"Atelier Virginie Morel"} />
            <div className="site-title"><Link to={homeUrl}>{siteTitle}</Link>{' '}</div>
            <div className={`site-nav${navVisible?' visible':''}`} role={"navigation"}>
                <ul className={"site-menu"}>
                    {['projects','references','atelier'].map((slug)=>
                        <li key={slug}><Link to={urlLang(slug)} hrefLang={lang}>{titleLang(slug)}</Link>{' '}</li>
                    )}
                </ul>
                <address className={"site-address"}>
                    {Nl2br(siteMeta('address'))}
                    <br/><span className="postcode">{siteMeta('postcode')}{' '}</span>{siteMeta('locality')}{' '}
                    <br/><span className="tabbed">{siteMeta('country')}{' '}</span>
                </address>
                <address className={"site-address"}>
                    {email && <><a href={`mailto:${email}`}>{email}</a>{' '}</>}
                    {phone && <><br/><a href={`tel:${phone}`}>{phoneFormat}</a>{' '}</>}
                    {facebook && <><br/><a href={facebook} className="tabbed" target={"_blank"} rel={"noreferrer noopener"}>facebook</a>{' '}</>}
                    {instagram && <><br/><a href={instagram} className="tabbed" target={"_blank"} rel={"noreferrer noopener"}>instagram</a>{' '}</>}
                </address>
            </div>
            <ul className={"site-languages"}>
                {Languages.map((l)=>
                    <li key={l}><Link to={urlLang(slug || 'projects',l)} hrefLang={l}>{l}</Link></li>
                )}
            </ul>
            <button id="nav-mobile" type={"button"} aria-label={"nav"} onClick={toggleNav}>
                <span />
                <span />
                <span />
            </button>
            <Link to={urlLang('agreement')} className={`button--terms button--terms--${navVisible?'visible':'hidden'}`}>{titleLang('agreement')}{' '}</Link>
        </header>
    )
}

export default Header;