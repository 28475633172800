import {graphql, useStaticQuery} from "gatsby";

const Links = () => {
    return useStaticQuery(graphql`
        query links {
            fr: siteMeta(lang: "fr")
            en: siteMeta(lang: "en")
            projects: pageLink(page_slug: "projects")
            atelier: pageLink(page_slug: "atelier")
            references: pageLink(page_slug: "references")
            agreement: pageLink(page_slug: "agreement")
        }
    `);
}

export default Links;