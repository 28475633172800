import React from "react"
import "reset.css"
import "../../scss/app.scss"
import PageLayout from "./pageLayout";

export const wrapPageElement = ({element, props}) => {
    return <PageLayout pageProps={props} pageEl={element} />

}

export const wrapRootElement = ({element}) => {
    return <div className={"site"}>
        {element}
        <div id={"modals-portal"} />
    </div>
}