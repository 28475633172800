import React, {useEffect,useState,createContext} from "react";
import Header from "./header";
import MainLayout from "./mainLayout";

export const ModalContext = createContext({visible:null})

const isBrowser = ()=>(typeof window !== 'undefined')
const isDesktop = ()=>isBrowser() && (window.innerWidth > 720)

const PageLayout = ({pageProps, pageEl}) => {
    const CurrentPageLayout = pageEl.type.Layout ?? MainLayout
    const isModal = CurrentPageLayout.isModal;
    const [mainEl,setMainEl] = useState(isModal?null:<CurrentPageLayout {...pageProps}>{pageEl}</CurrentPageLayout>)
    const [modalEl,setModalEl] = useState(isModal?<CurrentPageLayout {...pageProps}>{pageEl}</CurrentPageLayout>:null)
    const [pageViewCount,setPageViewCount] = useState(0);
    const {pageContext} = pageProps;
    pageContext.isDesktop = isDesktop()
    pageContext.pageViewCount = pageViewCount
    pageContext.isIntro = (isBrowser() && !window.introduction_ready);
    useEffect(()=>{
        setPageViewCount((currentCount)=>{
            const newPageCount = currentCount+(isModal || (isBrowser() && !window.introduction_ready) ?0:1);
            pageContext.isModal = isModal
            return newPageCount
        })
        if(isModal){
            setModalEl(<CurrentPageLayout {...pageProps}>{pageEl}</CurrentPageLayout>)
        }else{
            setMainEl(<CurrentPageLayout {...pageProps}>{pageEl}</CurrentPageLayout>)
        }
    },[pageEl,isModal,pageProps,pageContext])
    return <ModalContext.Provider value={{visible: isModal}}>
        <Header {...pageProps} paused={!!isModal} />
        {mainEl}
        {modalEl}
    </ModalContext.Provider>
}


export default PageLayout
